import React from "react";

import { ApiValidationErrors } from "@services/api-response";

export function ApiErrorDetails(props: ApiErrorDetailsProps): JSX.Element {
    const errorListItems =
        props.errors &&
        getErrorListItems(props.errors)?.map(
            (item: ApiErrorListItem, itemIndex: number) => {
                const subListItems = item?.subItems?.map(
                    (subItem: ApiErrorListItem, subItemIndex: number) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={`err-msg-${itemIndex}-${subItemIndex}`}>
                                <span>{subItem?.text}</span>
                            </li>
                        );
                    }
                );

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`err-msg-${itemIndex}`}>
                        <span>{item?.text}</span>
                        {subListItems && <ul>{subListItems}</ul>}
                    </li>
                );
            }
        );

    return (
        <div data-testid={props.testId}>
            {props.title && (
                <p data-testid={props.testId && `${props.testId}-title`}>
                    {props.title}
                </p>
            )}
            {props.detail && (
                <p data-testid={props.testId && `${props.testId}-detail`}>
                    {props.detail}
                </p>
            )}
            {errorListItems && (
                <>
                    <hr />
                    <ul
                        data-testid={
                            props.testId && `${props.testId}-err-msg-list`
                        }
                    >
                        {errorListItems}
                    </ul>
                </>
            )}
        </div>
    );
}

function getErrorListItems(
    errors: string[] | ApiValidationErrors
): ApiErrorListItem[] | undefined {
    const validationErrors = errors as ApiValidationErrors;
    if (validationErrors && !Array.isArray(validationErrors)) {
        return Object.keys(validationErrors).map((propName: string) => {
            return {
                subItems: (validationErrors[propName] as string[])?.map(
                    (message: string) => {
                        return {
                            text: message
                        };
                    }
                ),
                text: propName
            };
        });
    }

    const errorMessages = errors as string[];
    if (errorMessages && Array.isArray(errorMessages)) {
        return errorMessages.map((message: string) => {
            return {
                text: message
            };
        });
    }

    return undefined;
}

export class ApiErrorListItem {
    subItems?: ApiErrorListItem[];
    text: string;

    constructor(text: string) {
        this.text = text;
    }
}

export type ApiErrorDetailsProps = {
    detail?: string;
    errors?: string[] | ApiValidationErrors;
    testId?: string;
    title?: string;
};
