// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRheologyAnalysisResponse(obj: any): boolean {
    return (
        obj &&
        obj.cyRheology &&
        obj.cyRheology.samples &&
        Array.isArray(obj.cyRheology.samples) &&
        obj.cyRheology.samples[0]?.name !== undefined
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRheologyComparisonAnalysisResponse(obj: any): boolean {
    return (
        obj &&
        obj.rheologyComparison &&
        obj.rheologyComparison.samples &&
        Array.isArray(obj.rheologyComparison.samples) &&
        obj.rheologyComparison.samples[0]?.name !== undefined
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isViscosityAnalysisSampleResultGroup(obj: any): boolean {
    return (
        obj &&
        obj.name &&
        Array.isArray(obj.samples) &&
        obj.samples[0]?.name !== undefined
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleRawObject(rawObject: any) {
    if (isRheologyAnalysisResponse(rawObject.cyRheology)) {
        console.log("This is a RheologyAnalysisResponse");
        // Handle RheologyAnalysisResponse
    } else if (isViscosityAnalysisSampleResultGroup(rawObject)) {
        console.log("This is a ViscosityAnalysisResponse");
        // Handle ViscosityAnalysisResponse
    } else {
        console.log("Unknown response type");
    }
}
