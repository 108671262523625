import {
    AnalysisParameters,
    CyRheologyParameters,
    ViscosityParameters,
    RheologyComparisonParameters,
    ViscosityResinTypes
} from "../../../interfaces/analysis-types";
import {
    RheologyAnalysisRequest,
    RheologyComparisonAnalysisRequest,
    RheologyViscositySample,
    RheologyViscositySampleMeasurements
} from "@services/space/analysis/cy-rheology/models";
import {
    ViscosityAnalysisRequest,
    ViscosityAnalysisSample
} from "@services/space/analysis/viscosity/models";
import { BaseAnalysisRequest } from "@services/space/analysis/base-models";
import { StoredDataset } from "@services/space/datasets/models";

const resinTypeDictionary: { [key: string]: string } = {
    Other: "OTH",
    PE: "PE",
    PP: "PP",
    PS: "PS"
};

const getMetricIndex = (
    metrics: { identifier: string | null }[],
    targetIdentifier: string
): number => {
    return metrics.findIndex(
        (metric) => metric.identifier === targetIdentifier
    );
};

const getMetadataValue = (
    dataset: StoredDataset,
    identifier: string
): string | undefined => {
    return dataset.metadata
        .find((meta) =>
            meta.attributes.some((attr) => attr.identifier === identifier)
        )
        ?.attributes.find((attr) => attr.identifier === identifier)?.value as
        | string
        | undefined;
};

const getSeriesData = (
    dataset: StoredDataset,
    identifier: string
): number[] => {
    const series = dataset.series.find((s) =>
        s.metrics.some((metric) => metric.identifier === identifier)
    );
    if (!series) {
        return [];
    }
    const index = getMetricIndex(series.metrics, identifier);
    return series.dataPoints.map((point) => parseFloat(point[index] as string));
};

const buildRheologySample = (
    dataset: StoredDataset,
    params: CyRheologyParameters | RheologyComparisonParameters
): RheologyViscositySample => {
    const name = getMetadataValue(dataset, "sample_name") || dataset.fileName;
    const requestedBy = getMetadataValue(dataset, "sample_requested_by") || "";

    const resinTypeValue = params.resinType;
    const resinType =
        resinTypeDictionary[
            resinTypeValue as keyof typeof resinTypeDictionary
        ] || resinTypeDictionary.Other;

    const { fileName } = dataset;

    const referenceTemperature = Number(params.referenceTemp || "190.0");

    const vogelTemperature = Number(params.vogelTemp || "0.0");

    const measurements: RheologyViscositySampleMeasurements = {
        temperature: getSeriesData(dataset, "temperature"),
        angularFrequency: getSeriesData(dataset, "angular_frequency"),
        complexShearViscosity: getSeriesData(
            dataset,
            "complex_shear_viscosity"
        ),
        shearStorageModulus: getSeriesData(dataset, "shear_storage_modulus"),
        shearLossModulus: getSeriesData(dataset, "shear_loss_modulus")
    };

    return {
        name,
        requestedBy,
        resinType,
        fileName,
        referenceTemperature,
        vogelTemperature,
        measurements
    };
};

const buildViscositySample = (
    dataset: StoredDataset,
    viscosityParams: ViscosityParameters,
    isTTS: boolean
): ViscosityAnalysisSample => {
    const name = getMetadataValue(dataset, "sample_name") || dataset.fileName;
    const requestedBy = getMetadataValue(dataset, "sample_requested_by") || "";
    const { fileName } = dataset;

    const resinTypeValue = viscosityParams.resinType as ViscosityResinTypes;
    const resinType =
        resinTypeDictionary[resinTypeValue] || resinTypeDictionary.Other;

    const referenceTemperature = Number(
        viscosityParams.cyRheologyParams.referenceTemp || "190.0"
    );

    const vogelTemperature = Number(
        viscosityParams.cyRheologyParams.vogelTemp || "0.0"
    );

    const measurements = {
        temperature: getSeriesData(dataset, "temperature"),
        angularFrequency: getSeriesData(dataset, "angular_frequency"),
        complexShearViscosity: getSeriesData(
            dataset,
            "complex_shear_viscosity"
        ),
        shearStorageModulus: getSeriesData(dataset, "shear_storage_modulus"),
        shearLossModulus: getSeriesData(dataset, "shear_loss_modulus")
    };

    return {
        name,
        requestedBy,
        resinType,
        fileName,
        referenceTemperature,
        vogelTemperature: isTTS ? vogelTemperature : undefined,
        measurements
    };
};

export const buildRheologyAnalysisRequest = (
    cyRheologyParams: CyRheologyParameters,
    selectedDatasets: StoredDataset[]
): RheologyAnalysisRequest => {
    const isTTS = cyRheologyParams.dataType === "tempFreqSweep";

    // only indicate target sample if 'Compare Dataset' was checked
    const targetDataset = cyRheologyParams.isComparingDatasets
        ? cyRheologyParams.selectedDatasets.find(
              (dataset: StoredDataset) =>
                  dataset.fileName === cyRheologyParams.selectedCompareDataset
          ) || null
        : null;

    return {
        analysisType: isTTS ? "cyRheologyTTS" : "cyRheology",
        applyYieldStressTerm: cyRheologyParams.isUsingYieldStressTerm || false,
        fixedN: cyRheologyParams.fixN
            ? parseFloat(cyRheologyParams.nValue || "0")
            : null,
        samples: selectedDatasets.map((dataset: StoredDataset) => {
            return buildRheologySample(dataset, cyRheologyParams);
        }),
        targetSample:
            targetDataset !== null
                ? buildRheologySample(targetDataset, cyRheologyParams)
                : null
    };
};

export const buildViscosityAnalysisRequest = (
    viscosityParams: ViscosityParameters
): ViscosityAnalysisRequest => {
    const isTTS = viscosityParams.cyRheologyParams.dataType === "tempFreqSweep";

    const applyYieldStressTerm =
        viscosityParams.cyRheologyParams.isUsingYieldStressTerm || false;
    const fixedN = viscosityParams.cyRheologyParams.fixN
        ? parseFloat(viscosityParams.cyRheologyParams.nValue || "0")
        : null;

    const selectedDatasetGroups = viscosityParams.selectedDatasetGroups || [];

    const submittedBy = viscosityParams.submittedBy || "";
    const description = viscosityParams.description || "";

    const sampleGroups = selectedDatasetGroups.map((group) => {
        const samples = group.group.map((dataset) =>
            buildViscositySample(dataset, viscosityParams, isTTS)
        );
        return {
            name: group.name,
            samples
        };
    });
    const summaryAttributes = viscosityParams.summaryAttributes || [];

    return {
        analysisType: isTTS ? "viscosityTTS" : "viscosity",
        applyYieldStressTerm,
        fixedN,
        submittedBy,
        description,
        sampleGroups,
        summaryAttributes
    };
};

export const buildRheologyComparisonAnalysisRequest = (
    rheologyComparisonParams: RheologyComparisonParameters,
    selectedDatasets: StoredDataset[]
): RheologyComparisonAnalysisRequest => {
    const isTTS = rheologyComparisonParams.dataType === "tempFreqSweep";

    // only indicate target sample if 'Compare Dataset' was checked
    const targetDataset = rheologyComparisonParams.isComparingDatasets
        ? rheologyComparisonParams.selectedDatasets.find(
              (dataset: StoredDataset) =>
                  dataset.fileName ===
                  rheologyComparisonParams.selectedCompareDataset
          ) || null
        : null;

    return {
        analysisType: isTTS ? "rheologyComparisonTTS" : "rheologyComparison",
        applyYieldStressTerm:
            rheologyComparisonParams.isUsingYieldStressTerm || false,
        fixedN: rheologyComparisonParams.fixN
            ? parseFloat(rheologyComparisonParams.nValue || "0")
            : null,
        samples: selectedDatasets.map((dataset: StoredDataset) => {
            return buildRheologySample(dataset, rheologyComparisonParams);
        }),
        targetSample:
            targetDataset !== null
                ? buildRheologySample(targetDataset, rheologyComparisonParams)
                : null
    };
};

export const buildAnalysisRequest = async (
    analysisParams: AnalysisParameters
): Promise<
    BaseAnalysisRequest | ViscosityAnalysisRequest | RheologyAnalysisRequest
> => {
    switch (analysisParams.analysisType) {
        case "cyRheology":
            if (analysisParams.cyRheologyParams) {
                return buildRheologyAnalysisRequest(
                    analysisParams.cyRheologyParams,
                    analysisParams.cyRheologyParams.selectedDatasets || []
                );
            }
            throw new Error(
                "cyRheologyParams are required for cyRheology analysis."
            );

        case "viscosity":
            return buildViscosityAnalysisRequest(
                analysisParams.viscosityParams!
            );

        case "rheologyComparison":
            return buildRheologyComparisonAnalysisRequest(
                analysisParams.rheologyComparisonParams!,
                analysisParams.rheologyComparisonParams!.selectedDatasets || []
            );
        default:
            throw new Error(
                `Unsupported analysis type: ${analysisParams.analysisType}`
            );
    }
};
