import React, { useState } from "react";
import { BaseCyRheologyForm } from "./base-cy-rheology-form";
import { DataTypeSection } from "./components/data-type-section";
import { FixNSection } from "./components/fix-n-section";
import { ReferenceTempSection } from "./components/reference-temp-section";
import { YieldStressTermSection } from "./components/yield-stress-term-section";
import { VogelTempSection } from "./components/vogel-temp-section";
import { ResinTypeSection } from "./components/resin-type-section";
import { CompareDatasetsSection } from "./components/compare-datasets-section";
import { DatasetsSection } from "./components/datasets-section";
import { CyRheologyParameters } from "../../../interfaces/analysis-types";
import { ValidateCyRheologyParameters } from "./cy-rheology-validations";
import { SpaceAccordion } from "../../index";
import { getTestIds } from "./cy-test-ids";
import { Divider } from "@cpchem/covalence-ui";
import "../styles.scss";
import { useService } from "../../../service-provider";
import { FeatureFlagService } from "@services/feature-flags/interface";
import { FeatureFlagServiceKey } from "@services/feature-flags";

interface CyRheologyFormProps {
    initialParameters: CyRheologyParameters;
    onUpdate: (updates: Partial<CyRheologyParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isRheologyComparison?: boolean;
    isLoading?: boolean;
    testId?: string;
}

export function CyRheologyForm({
    initialParameters,
    onUpdate,
    onValidation,
    isRheologyComparison,
    isLoading,
    testId
}: CyRheologyFormProps): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState<string | null>(
        "Datasets"
    );
    const featureFlagService = useService<FeatureFlagService>(
        FeatureFlagServiceKey
    );

    const featureFlags = featureFlagService.getFlagsHook()();

    const {
        selectedDatasetsTestId,
        resinTypeTestId,
        compareDatasetsTestId,
        dataTypesTestId,
        fixNTestId,
        vogelTempTestId,
        referenceTempTestId,
        yieldStressTestId
    } = getTestIds(testId);

    return (
        <BaseCyRheologyForm<CyRheologyParameters>
            initialParameters={initialParameters}
            onUpdate={onUpdate}
            onValidation={onValidation}
            isLoading={isLoading}
            validate={ValidateCyRheologyParameters}
        >
            {({ parameters, handleFieldUpdate, isLoading }) => {
                const isCompareDatasetsDisabled =
                    parameters.selectedDatasets.length <= 1 ||
                    parameters.dataType === "tempFreqSweep";

                const compareDatasetsTooltipText =
                    parameters.dataType === "tempFreqSweep"
                        ? "A Target Comparison report cannot be performed with a TTS report"
                        : "Please add more datasets to compare";
                return (
                    <div className="parameters-content">
                        <SpaceAccordion
                            label="Datasets"
                            isOpen={openAccordion === "Datasets"}
                            onToggle={() =>
                                setOpenAccordion(
                                    openAccordion === "Datasets"
                                        ? null
                                        : "Datasets"
                                )
                            }
                            defaultOpen
                            testId={`${testId}-datasets-accordion`}
                        >
                            <DatasetsSection
                                selectedDatasets={parameters.selectedDatasets}
                                onUpdateSelectedDatasets={(datasets) => {
                                    const removedDataset =
                                        parameters.selectedDatasets.find(
                                            (dataset) =>
                                                !datasets.some(
                                                    (d) =>
                                                        d.fileName ===
                                                        dataset.fileName
                                                )
                                        );

                                    handleFieldUpdate(
                                        "selectedDatasets",
                                        datasets
                                    );

                                    if (removedDataset) {
                                        if (
                                            removedDataset.fileName ===
                                            parameters.selectedCompareDataset
                                        ) {
                                            handleFieldUpdate(
                                                "selectedCompareDataset",
                                                datasets.length > 0
                                                    ? datasets[0].fileName
                                                    : null
                                            );
                                        }
                                    }

                                    if (datasets.length <= 1) {
                                        handleFieldUpdate(
                                            "isComparingDatasets",
                                            false
                                        );
                                    }
                                }}
                                isLoading={isLoading}
                                isGroupedDatasets={false}
                                testId={selectedDatasetsTestId}
                            />
                        </SpaceAccordion>
                        <Divider />
                        <SpaceAccordion
                            label="Report Parameters"
                            isOpen={openAccordion === "Report Parameters"}
                            onToggle={() =>
                                setOpenAccordion(
                                    openAccordion === "Report Parameters"
                                        ? null
                                        : "Report Parameters"
                                )
                            }
                            testId={`${testId}-report-parameters-accordion`}
                        >
                            <ResinTypeSection
                                resinType={parameters.resinType}
                                onChange={(value) =>
                                    handleFieldUpdate("resinType", value)
                                }
                                isDisabled={isLoading}
                                testId={resinTypeTestId}
                            />
                            <DataTypeSection
                                dataType={parameters.dataType}
                                onChange={(value) => {
                                    handleFieldUpdate("dataType", value);
                                    if (value === "frequencySweep") {
                                        handleFieldUpdate("vogelTemp", "0.0");
                                    } else {
                                        handleFieldUpdate(
                                            "isComparingDatasets",
                                            false
                                        );
                                    }
                                }}
                                isDisabled={isLoading}
                                testId={dataTypesTestId}
                            />
                            <FixNSection
                                fixN={parameters.fixN}
                                nValue={parameters.nValue}
                                onFixNChange={(value) =>
                                    handleFieldUpdate("fixN", value)
                                }
                                onNValueChange={(value) =>
                                    handleFieldUpdate("nValue", value)
                                }
                                isDisabled={isLoading}
                                testId={fixNTestId}
                            />
                            <VogelTempSection
                                vogelTemp={parameters.vogelTemp}
                                onChange={(value) =>
                                    handleFieldUpdate("vogelTemp", value)
                                }
                                isDisabled={
                                    isLoading ||
                                    parameters.dataType === "frequencySweep"
                                }
                                testId={vogelTempTestId}
                            />
                            <ReferenceTempSection
                                referenceTemp={parameters.referenceTemp}
                                onChange={(value) =>
                                    handleFieldUpdate("referenceTemp", value)
                                }
                                isDisabled={isLoading}
                                testId={referenceTempTestId}
                            />
                            <YieldStressTermSection
                                isUsingYieldStressTerm={
                                    parameters.isUsingYieldStressTerm
                                }
                                onChange={(checked) =>
                                    handleFieldUpdate(
                                        "isUsingYieldStressTerm",
                                        checked
                                    )
                                }
                                isDisabled={isLoading}
                                testId={yieldStressTestId}
                            />
                            {featureFlags.enableCyRheologyAnalysisTargetComparison ===
                                true && (
                                <CompareDatasetsSection
                                    isComparingDatasets={
                                        parameters.isComparingDatasets
                                    }
                                    selectedCompareDataset={
                                        parameters.selectedCompareDataset
                                    }
                                    selectedDatasets={
                                        parameters.selectedDatasets
                                    }
                                    onToggleComparingDatasets={(checked) => {
                                        handleFieldUpdate(
                                            "isComparingDatasets",
                                            checked
                                        );

                                        // default target dataset to first one in the list if
                                        // the option is checked and no selection has been made yet
                                        if (
                                            checked &&
                                            !parameters.selectedCompareDataset
                                        ) {
                                            handleFieldUpdate(
                                                "selectedCompareDataset",
                                                parameters.selectedDatasets[0]
                                                    .fileName
                                            );
                                        }
                                    }}
                                    onSelectCompareDataset={(value) =>
                                        handleFieldUpdate(
                                            "selectedCompareDataset",
                                            value
                                        )
                                    }
                                    tooltipText={
                                        isCompareDatasetsDisabled
                                            ? compareDatasetsTooltipText
                                            : undefined
                                    }
                                    isRheologyComparison={isRheologyComparison}
                                    isDisabled={
                                        isLoading || isCompareDatasetsDisabled
                                    }
                                    testId={compareDatasetsTestId}
                                />
                            )}
                        </SpaceAccordion>
                    </div>
                );
            }}
        </BaseCyRheologyForm>
    );
}
