import { LogLevel, log } from "@cpchem/logging";

export type ApiFileResponse = {
    filename: string;
    content: Blob;
};

export type ApiErrorResponse = {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    [extensionPropName: string]: unknown;
};

export interface ApiDetailedErrorResponse extends ApiErrorResponse {
    errors: string[] | ApiValidationErrors;
}

export type ApiValidationErrors = {
    [propName: string]: string[];
};

export async function getApiFileResponse(
    res: Response
): Promise<ApiFileResponse | ApiErrorResponse> {
    const contentDisposition = res.headers.get("Content-Disposition");
    const contentDispositionParts = contentDisposition?.split(/\s*;\s*/) ?? [];

    let filename = "";
    for (
        let partIndex = 0;
        partIndex < contentDispositionParts.length;
        partIndex++
    ) {
        const match = contentDispositionParts[partIndex].match(
            /^filename\s*\*?\s*=\s*"?(.*?)"?$/
        );
        if (match?.length === 2) {
            [, filename] = match;
            break;
        }
    }

    const content = await res.blob();

    return {
        filename,
        content
    };
}

export type ApiResponse<T> = {
    data: T;
};

export function handle400Response(): ApiErrorResponse {
    log(
        `Bad request. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        title: "Bad request. Please check your request and try again."
    };
}

export function handle401Response(): ApiErrorResponse {
    log(`You are not authorized to make this call.`, LogLevel.ERROR);
    return {
        title: "You are not authorized to make this call."
    };
}

export function handle404Response(): ApiErrorResponse {
    log(
        `Data not found. Please check your request and try again.`,
        LogLevel.ERROR
    );
    return {
        title: "Data not found. Please check your request and try again."
    };
}

export function handle409Response(): ApiErrorResponse {
    log(
        `The server states that the request could not be completed due to a conflict with the current state of the target resource.`,
        LogLevel.ERROR
    );
    return {
        title: "The server states that the request could not be completed due to a conflict with the current state of the target resource."
    };
}

export function handle422Response(): ApiErrorResponse {
    log(
        `Your request was valid, but the server could not process it.`,
        LogLevel.ERROR
    );
    return {
        title: "Your request was valid, but the server could not process it."
    };
}

export function handle500Response(): ApiErrorResponse {
    log(
        `The server encountered an unexpected condition that prevented it from fulfilling the request`,
        LogLevel.ERROR
    );
    return {
        title: "The server encountered an unexpected condition that prevented it from fulfilling the request."
    };
}

export type errorResponseValue = { [key: number]: () => ApiErrorResponse };

export const errorResponseDictionary: errorResponseValue = {
    400: handle400Response,
    401: handle401Response,
    404: handle404Response,
    409: handle409Response,
    422: handle422Response,
    500: handle500Response
};
