import React from "react";
import { RheologyComparisonParameters } from "../../../interfaces/analysis-types";
import { CyRheologyForm } from "../cy-rheology/cy-rheology-form";

interface RheologyComparisonFormProps {
    initialParameters: RheologyComparisonParameters;
    onUpdate: (updates: Partial<RheologyComparisonParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function RheologyComparisonForm({
    initialParameters,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: RheologyComparisonFormProps): JSX.Element {
    return (
        <CyRheologyForm
            initialParameters={initialParameters}
            onUpdate={onUpdate}
            onValidation={onValidation}
            testId={testId}
            isRheologyComparison
            isLoading={isLoading}
        />
    );
}
