import React from "react";
import ViscosityOverlayPreviewChart from "./viscosity-overlay-preview-chart";
import { PlotData } from "plotly.js";
import { AnalysisResponseType } from "@stores/datasets/datasets-types";
import {
    StyleAssignment,
    StyleAssignments
} from "../../../utilities/constants/chart-colors";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import { LOADING_USER_REPORT } from "../../../logging/log-events";
import { ViscosityAnalysisSampleResultGroup } from "@services/space/analysis/viscosity/models";
import {
    isRheologyAnalysisResponse,
    isRheologyComparisonAnalysisResponse,
    isViscosityAnalysisSampleResultGroup
} from "../../../utilities/report-analysis/analysis-utils/type-checks";
import { RheologyAnalysisSampleResult } from "@services/space/analysis/cy-rheology/models";

export interface IViscosityOverlayPreviewChartDataProviderProps {
    RawData: AnalysisResponseType | ViscosityAnalysisSampleResultGroup;
    Temp: string;
    testId?: string;
}

const ViscosityOverlayPreviewChartDataProvider: React.FC<
    IViscosityOverlayPreviewChartDataProviderProps
> = (props: IViscosityOverlayPreviewChartDataProviderProps) => {
    const { RawData, Temp, testId } = props;

    const styleAssignment: StyleAssignment[] = StyleAssignments;

    const getSeriesDataFromRawData = (
        RawData: AnalysisResponseType | ViscosityAnalysisSampleResultGroup
    ): PlotData[] => {
        const series: PlotData[] = [];
        if (
            isRheologyAnalysisResponse(RawData) ||
            isRheologyComparisonAnalysisResponse(RawData)
        ) {
            const emptySampleArray: RheologyAnalysisSampleResult[] = [];
            const response = RawData as AnalysisResponseType;
            const samples =
                response.cyRheology !== undefined
                    ? response.cyRheology.samples
                    : response.rheologyComparison !== undefined
                      ? response.rheologyComparison.samples
                      : emptySampleArray;
            samples.forEach((sample, index) => {
                const measurementFrequency: number[] =
                    sample.measurements.angularFrequency;
                const measurementViscosity: number[] =
                    sample.measurements.complexShearViscosity;
                const modelFrequency: number[] =
                    sample.viscosityModel.angularFrequency;
                const modelViscosity: number[] =
                    sample.viscosityModel.complexShearViscosity;
                const lastStyleAssignmentIndex: number =
                    styleAssignment.length - 1;
                const styleAssignmentIndex: number =
                    index < styleAssignment.length
                        ? index
                        : lastStyleAssignmentIndex;
                const { color, symbol, dash } =
                    styleAssignment[styleAssignmentIndex];
                const width = 2;
                const shape = "spline";

                // Measurement
                series.push({
                    x: measurementFrequency,
                    y: measurementViscosity,
                    type: "scatter",
                    mode: "markers",
                    name: `${sample.name}`,
                    marker: { color, symbol }
                } as PlotData);
                // CY Fit
                series.push({
                    x: modelFrequency,
                    y: modelViscosity,
                    type: "scatter",
                    mode: "lines",
                    name: `${sample.name} CY Model`,
                    marker: { color },
                    line: { color, width, shape, dash }
                } as PlotData);
            });
        }
        if (isViscosityAnalysisSampleResultGroup(RawData)) {
            const emptySampleArray: RheologyAnalysisSampleResult[] = [];
            const response = RawData as ViscosityAnalysisSampleResultGroup;
            const samples =
                response !== undefined ? response.samples : emptySampleArray;
            samples.forEach((sample, index) => {
                const measurementFrequency: number[] =
                    sample.measurements.angularFrequency;
                const measurementViscosity: number[] =
                    sample.measurements.complexShearViscosity;
                const modelFrequency: number[] =
                    sample.viscosityModel.angularFrequency;
                const modelViscosity: number[] =
                    sample.viscosityModel.complexShearViscosity;
                const lastStyleAssignmentIndex: number =
                    styleAssignment.length - 1;
                const styleAssignmentIndex: number =
                    index < styleAssignment.length
                        ? index
                        : lastStyleAssignmentIndex;
                const { color, symbol, dash } =
                    styleAssignment[styleAssignmentIndex];
                const width = 2;
                const shape = "spline";

                // Measurement
                series.push({
                    x: measurementFrequency,
                    y: measurementViscosity,
                    type: "scatter",
                    mode: "markers",
                    name: `${sample.name}`,
                    marker: { color, symbol }
                } as PlotData);
                // CY Fit
                series.push({
                    x: modelFrequency,
                    y: modelViscosity,
                    type: "scatter",
                    mode: "lines",
                    name: `${sample.name} CY Model`,
                    marker: { color },
                    line: { color, width, shape, dash }
                } as PlotData);
            });
        }

        return series;
    };

    const data = getSeriesDataFromRawData(RawData);

    spaceTrackEvent({
        type: LOADING_USER_REPORT,
        contents: JSON.stringify({ rawData: RawData, seriesData: data })
    });

    return (
        <ViscosityOverlayPreviewChart data={data} temp={Temp} testId={testId} />
    );
};

export default ViscosityOverlayPreviewChartDataProvider;
