export interface ProvidedConfig {
    api: {
        personalization: {
            url: string;
            scopes: string[];
        };
        datasetFormats: {
            url: string;
            scopes: string[];
        };
        datasetConvert: {
            url: string;
            scopes: string[];
        };
        rheologyFrequency: {
            url: string;
            scopes: string[];
        };
        rheologyTTS: {
            url: string;
            scopes: string[];
        };
        viscosityFrequency: {
            url: string;
            scopes: string[];
        };
        viscosityTTS: {
            url: string;
            scopes: string[];
        };
        resinTypes: {
            url: string;
            scopes: string[];
        };
        viscositySummaryAttributeTypes: {
            url: string;
            scopes: string[];
        };
        rheologyComparison: {
            url: string;
            scopes: string[];
        };
    };
    msal: {
        authority: string;
        clientId: string;
        redirectUri: string;
        scopes: string[];
    };
    graph: {
        photo: {
            endpoint: string;
            scopes: string[];
        };
    };
    logging: {
        appInsights: {
            connectionString: string;
        };
        logRocketAppId: string;
    };
    featureFlags: {
        launchDarkly: {
            clientSideId: string;
        };
    };
    mocks?: {
        mockUserEndpoint: string;
    };
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}

export function getProvidedConfig(env?: string): ProvidedConfig {
    return env === "development"
        ? {
              api: {
                  personalization: {
                      url: "https://api-dev.cpchem.com/personalization/v1/personalization",
                      scopes: [
                          "https://api-dev.cpchem.com/Personalization.FullAccess"
                      ]
                  },
                  datasetFormats: {
                      url: "https://api-dev.cpchem.com/space/datasetFormats",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  datasetConvert: {
                      url: "https://api-dev.cpchem.com/space/datasets/convert",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  rheologyFrequency: {
                      url: "https://api-dev.cpchem.com/space/analysis/rheology/frequency",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  rheologyTTS: {
                      url: "https://api-dev.cpchem.com/space/analysis/rheology/tts",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  viscosityFrequency: {
                      url: "https://api-dev.cpchem.com/space/analysis/viscosity/frequency",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  viscosityTTS: {
                      url: "https://api-dev.cpchem.com/space/analysis/viscosity/tts",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  resinTypes: {
                      url: "https://api-dev.cpchem.com/space/resinTypes",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  viscositySummaryAttributeTypes: {
                      url: "https://api-dev.cpchem.com/space/viscositySummaryAttributeTypes",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  },
                  rheologyComparison: {
                      url: "https://api-dev.cpchem.com/space/analysis/comparison/rheology",
                      scopes: ["https://api-dev.cpchem.com/SPACE.FullAccess"]
                  }
              },
              msal: {
                  authority:
                      "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                  clientId: "9c3e3b96-4f8d-4c2c-9e82-a419940eef51",
                  redirectUri: "https://localhost:3000/auth_redirect",
                  scopes: [
                      "https://api-dev.cpchem.com/SPACE.FullAccess",
                      "https://api-dev.cpchem.com/Personalization.FullAccess"
                  ]
              },
              graph: {
                  photo: {
                      endpoint:
                          "https://graph.microsoft.com/v1.0/me/photo/$value",
                      scopes: ["https://graph.microsoft.com/User.Read"]
                  }
              },
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=00000000-0000-0000-0000-000000000000"
                  },
                  logRocketAppId: "cpchem/test"
              },
              featureFlags: {
                  launchDarkly: {
                      clientSideId: "66f564de8940f90841337590"
                  }
              },
              mocks: {
                  mockUserEndpoint: "http://localHost:59850/getMockUser"
              }
          }
        : getConfigFromIndex();
}
