import React, { useState } from "react";
import { PlotData } from "plotly.js";
import { AnalysisResponseType } from "@stores/datasets/datasets-types";
import {
    StyleAssignment,
    StyleAssignments
} from "../../../utilities/constants/chart-colors";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import { LOADING_USER_REPORT } from "../../../logging/log-events";
import { RheologyAnalysisSampleResult } from "@services/space/analysis/cy-rheology/models";
import { Carousel } from "../../../components/carousel/carousel";
import ViscosityOverlayPreviewChartDataProvider from "./viscosity-overlay-preview-chart-data-provider";
import TargetComparisonPreviewChart from "./target-comparison-preview-chart";

export interface ITargetComparisonPreviewChartDataProviderProps {
    RawData: AnalysisResponseType;
    TargetSampleName: string;
    Temp: string;
    testId?: string;
}

const TargetComparisonPreviewChartDataProvider: React.FC<
    ITargetComparisonPreviewChartDataProviderProps
> = (props: ITargetComparisonPreviewChartDataProviderProps) => {
    const { RawData, TargetSampleName, Temp, testId } = props;

    const styleAssignments: StyleAssignment[] = StyleAssignments;

    const getSeriesDataFromRawDataForTargetComparison = (
        RawData: AnalysisResponseType
    ): PlotData[] => {
        const series: PlotData[] = [];
        const emptySampleArray: RheologyAnalysisSampleResult[] = [];
        const response = RawData as AnalysisResponseType;
        const samples =
            response.cyRheology !== undefined
                ? response.cyRheology.samples
                : response.rheologyComparison !== undefined
                  ? response.rheologyComparison.samples
                  : emptySampleArray;

        // Make target first item rendered in preview target comparison
        const targetIndex = samples.findIndex(
            (el) => el.name === TargetSampleName
        );
        const target = samples[targetIndex];
        samples.splice(targetIndex, 1);
        samples.unshift(target);

        samples.forEach((sample, index) => {
            if (sample.targetComparison) {
                const measurementFrequency: number[] =
                    sample.targetComparison.angularFrequency;
                const { viscosityPercentDifference } = sample.targetComparison;
                const lastStyleAssignmentIndex: number =
                    styleAssignments.length - 1;
                const styleAssignmentIndex: number =
                    index < styleAssignments.length
                        ? index
                        : lastStyleAssignmentIndex;
                const { color, symbol, dash } =
                    styleAssignments[styleAssignmentIndex];
                const width = 2;
                const shape = "spline";

                // Measurement
                series.push({
                    x: measurementFrequency,
                    y: viscosityPercentDifference,
                    type: "scatter",
                    mode: "lines+markers",
                    name: `${sample.name} CY Model`,
                    marker: { color, symbol },
                    line: { color, width, shape, dash }
                } as PlotData);
            }
        });

        return series;
    };

    const data = getSeriesDataFromRawDataForTargetComparison(RawData);

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const carouselLabels: string[] = ["Viscosity Overlay", "Target Comparison"];

    spaceTrackEvent({
        type: LOADING_USER_REPORT,
        contents: JSON.stringify({ rawData: RawData, seriesData: data })
    });

    return (
        <Carousel
            label={carouselLabels[activeItemIndex]}
            testId={testId}
            initialActiveItemIndex={0}
            onActiveItemIndexChange={(idx) => setActiveItemIndex(idx)}
        >
            <ViscosityOverlayPreviewChartDataProvider
                RawData={RawData}
                Temp={Temp}
                testId={testId}
            />
            <TargetComparisonPreviewChart
                data={data}
                targetSampleName={TargetSampleName}
                temp={Temp}
                testId={testId}
            />
        </Carousel>
    );
};

export default TargetComparisonPreviewChartDataProvider;
